var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select-box",
    _vm._b(
      {
        attrs: { headerClass: _vm.headerClass },
        scopedSlots: _vm._u([
          {
            key: "time-ago",
            fn: function () {
              return [
                _vm.showArrivalTime
                  ? _c("arrival-time", {
                      attrs: {
                        startDate: _vm.data.date,
                        arrivalDate: _vm.data.arrival_time,
                      },
                      on: {
                        "set-class": function (mode) {
                          return (_vm.headerClass = "mode-" + mode)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.data.value,
          callback: function ($$v) {
            _vm.$set(_vm.data, "value", $$v)
          },
          expression: "data.value",
        },
      },
      "select-box",
      _vm.bind,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }